<template>
    <div class="body">
        <div class="wrapper">
            <header :style="headerBackground"></header>
            <div class="container">
                <div class="logo">
                    <img :src="logoImg" width="300px" height="100%" />
                </div>

                <div class="topArea">
                    <div class="formWrapper" v-if="!requestSent">
                        <div class="lang-select">
                            <lang-select></lang-select>
                        </div>
                        <div class="ptitle">{{ $t('lead.register-title') }}</div>

                        <p>{{ $t('lead.add-email') }}</p>

                        <div class="form-group">
                            <input type="email" name="email" v-model="email" :placeholder="$t('lead.add-email-ph')" />
                        </div>

                        <div class="form-group">
                            <input type="text" v-model="phone" :placeholder="$t('lead.add-phone')" />
                        </div>

                        <div class="form-group" v-if="!requestExists">
                            <el-button :loading="isLoading" @click.stop.prevent="sendRegisterRequest" class="btn">
                                {{ $t('lead.want-to-sell') }}</el-button
                            >
                        </div>
                        <div class="form-group" v-else>
                            <el-button
                                :loading="isLoading"
                                @click.stop.prevent="resendRegisterRequestEmail"
                                class="btn"
                                >{{ $t('lead.button-resend-email') }}</el-button
                            >
                        </div>
                        <span>
                            <router-link to="/login">
                                {{ $t('lead.already-register') }}
                            </router-link>
                        </span>
                    </div>
                    <div class="formWrapper" v-else>
                        <p>
                            <span style="color:#00ab00" v-html="$t('lead.request-success')"></span>
                        </p>
                        <div class="form-group">
                            <el-button
                                :loading="isLoading"
                                @click.stop.prevent="resendRegisterRequestEmail"
                                class="btn"
                                >{{ $t('lead.button-resend-email') }}</el-button
                            >
                        </div>
                        <span>
                            <router-link to="/login">
                                {{ $t('lead.already-register') }}
                            </router-link>
                        </span>
                    </div>
                    <div class="mpInfo">
                        <div class="colWrapper">
                            <div class="col">
                                <div class="icon">
                                    <svg-icon icon-class="shopping-basket" />
                                </div>

                                <div class="text">{{ $t('lead.msg-1') }}</div>
                            </div>
                        </div>

                        <div class="colWrapper">
                            <div class="col">
                                <div class="icon">
                                    <svg-icon icon-class="commerce" />
                                </div>

                                <div class="text">{{ $t('lead.msg-2') }}</div>
                            </div>
                        </div>

                        <div class="colWrapper">
                            <div class="col">
                                <div class="icon">
                                    <svg-icon icon-class="responsive" />
                                </div>

                                <div class="text">{{ $t('lead.msg-3') }}</div>
                            </div>
                        </div>

                        <div class="colWrapper">
                            <div class="col">
                                <div class="icon">
                                    <svg-icon icon-class="seo" />
                                </div>
                                <div class="text" v-html="$t('lead.msg-4')"></div>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </div>

                <div class="middleArea">
                    <!-- <div class="infoText">
						<div class="ptitle">{{$t('lead.msg-5')}} </div>
						<p></p>
					</div> -->

                    <div class="separator"></div>

                    <div class="stepsWrapper">
                        <div class="step">
                            <div class="numberCol">
                                <div class="number">1</div>
                            </div>

                            <div class="infoCol">
                                <div class="ptitle">{{ $t('lead.msg-6') }}</div>

                                <ul>
                                    <li>{{ $t('lead.msg-7') }}</li>
                                    <li>{{ $t('lead.msg-8') }}</li>
                                    <li>{{ $t('lead.msg-9') }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="step">
                            <div class="numberCol">
                                <div class="number">2</div>
                            </div>

                            <div class="infoCol">
                                <div class="ptitle">{{ $t('lead.msg-10') }}</div>

                                <ul>
                                    <li>{{ $t('lead.msg-11') }}</li>
                                    <li>{{ $t('lead.msg-12') }}</li>
                                    <li>{{ $t('lead.msg-13') }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="step">
                            <div class="numberCol">
                                <div class="number">3</div>
                            </div>

                            <div class="infoCol">
                                <div class="ptitle">{{ $t('lead.msg-14') }}</div>

                                <ul>
                                    <li>{{ $t('lead.msg-15') }}</li>
                                    <li>{{ $t('lead.msg-16') }}</li>
                                    <li>{{ $t('lead.msg-17') }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="step">
                            <div class="numberCol">
                                <div class="number">4</div>
                            </div>

                            <div class="infoCol">
                                <div class="ptitle">{{ $t('lead.msg-18') }}</div>

                                <ul>
                                    <li>{{ $t('lead.msg-19') }}</li>
                                    <li>{{ $t('lead.msg-20') }}</li>
                                    <li>{{ $t('lead.msg-21') }}</li>
                                </ul>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="stats">
                    <h1 align="center" style="font-size:27px">
                        Marketplace-ul CEL.ro, o opțiune rapidă de creștere a vânzărilor<br />
                        pentru afacerile mici si nu numai
                    </h1>
                    <br />

                    <p align="left">
                        <strong>Marketplace-ul CEL.ro</strong> reprezintă o oportunitate rapidă, simplă și eficientă de
                        a comercializa produse pe site-ul www.cel.ro și se adresează atât comercianților online și
                        offline, cât și importatorilor sau producătorilor. Platforma permite furnizorilor să își
                        stabilească singuri condițiile comerciale, taxele de livrare, politica și taxele de retur, dar
                        și prețurile, atât timp cât asigură clienților aceleași standarde de calitate cu care s-au
                        obișnuit la CEL.ro. Lansat la începutul anului 2018, Marketplace-ul CEL.ro a ajuns la
                        <strong>1800 de parteneri înregistrați</strong> și peste 1 milion de produse listate, iar în
                        ultimul an vânzările au crescut cu peste 500%, ajungând până la
                        <strong>15.000 comenzi lunare</strong> plasate pe site doar pentru partenerii din Marketplace.
                    </p>
                    <br />

                    <p align="left">
                        Marketplace-ul CEL.ro vine în avantajul companiilor la început de drum și Start-up-urilor care
                        pot vinde direct și astfel nu au nevoie de un site propriu. Acestea pot testa gratuit un nou
                        canal de vânzare, iar investiția inițială necesară este mult redusă, accesând în același timp și
                        o bază impresionantă de clienți. Practic, Marketplace-ul CEL.ro este o platformă de comerț
                        electronic care poate fi folosită de orice industrie sau nișă specializată.
                    </p>
                    <p align="left">
                        Pentru ca marketplace-ul se adreseaza celor care nu au un buget foarte mare si nici cunostinte
                        tehnice, am creat o versiune a API-ului CEL.ro ce este 100% compatibila cu cea a API-ului
                        Marketplace-urilor concurente. Daca ai deja o implementare facuta pentru pentru
                        Marketplace-urile concurente acum este foarte simplu sa folosesti acelasi cod pentru a exporta
                        produsele si a prelua comenzile din marketplace-ul CEL.ro.
                    </p>
                    <p align="left">
                        În ultimul an <strong>CEL.ro a investit peste 500.000 euro</strong> în tehnologie și dezvoltarea
                        platformei, oferind funcționalități noi care au dus la o dublare a vânzărilor în platformă în
                        ultimele luni. Deasemenea CEL.ro oferă suport dedicat tuturor partenerilor și cere constant
                        feedback în vederea îmbunătățirea funcționalităților oferite.
                    </p>
                    <br />

                    <p align="left">
                        Beneficiile oferite de către platforma Marketplace CEL.ro, printre care enumeram: integrare
                        rapidă prin xls sau API, SMS notificare clienți, sistem de generare facturi, integrare automată
                        cu firme de curierat, afișarea unui număr nelimitat de promoții, sunt în totalitate gratuite.
                    </p>
                    <p align="left">
                        Marketplace-ul implică și partea de promovare pentru parteneri: magazinul online oferă toate
                        canalele de marketing pentru facilitarea promovării produselor punând la dispoziția partenerilor
                        know-how-ul acumulat <strong> în experiență de peste 15 ani în online</strong>.
                    </p>
                    <br />

                    <p align="left">
                        Platforma a înregistrat vânzări crescute la gama de accesorii telefoane mobile, mobilă, fashion
                        și cosmetice, bricolaj, hobby și articole sportive, subliniandu-se astfel interesul crescut al
                        clienților față de produsele din afară gamei electro-IT. Cele mai noi categorii adăugate sunt:
                        suplimente alimentare, piese auto, obiecte de artă sau materiale de construcții.
                    </p>
                    <p align="left">
                        Pentru anul 2019, CEL.ro își propune ca obiectiv să înregistreze încă 3000 de comercianți noi,
                        care să aducă peste 2 milioane de produse, iar în acest sens își va concentra toate eforturile
                        pe procese, fluxuri și automatizări suplimentare, ajutându-i pe parteneri să vândă mai mult.
                    </p>
                    <!-- <p align="left">*Comision 0 valabil pentru partenerii inregistrati noi. Vezi regulamentul <b><a
								target="_blank"
								href="https://api-mp.cel.ro/market_cdn/regulament_promotie_30.pdf">aici</a></b></p> -->
                    <p>&nbsp;</p>
                </div>
            </div>

            <div class="copyright">
                <div class="company">Copyright BRAND DESIGN TEAM SRL</div>
                <div>
                    RO38170983 J40/15195/2017 sediu Str Iuliu Maniu, nr 7, corp A, parter, cam 15-35, sector 6,
                    Bucuresti RO69RZBR0000060021977993
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headerImg from '@/assets/images/header.jpg';
import logoImg from '@/assets/images/lead/logo.png';
import LangSelect from '@/components/LangSelect/text.vue';
import { sendRegisterRequest, resendRegisterRequestEmail } from '@/api/lead';

import { Message } from 'element-ui';
import has from 'lodash.has';

export default {
    name: 'Lead',
    metaInfo() {
        return {
            title: 'CEL.ro Marketplace',
            meta: [
                {
                    name: 'description',
                    content: 'marketplace cel.ro'
                },
                {
                    name: 'keywords',
                    content: 'marketplace cel.ro'
                },
                { name: 'robots', content: 'index,follow' }
            ]
        };
    },
    components: {
        LangSelect
    },
    data() {
        return {
            logoImg,
            headerImg,
            email: '',
            phone: '',
            requestId: '',
            requestSent: false,
            requestExists: false,
            isLoading: false
        };
    },
    methods: {
        sendRegisterRequest() {
            const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/;
            if (!reg.test(this.email)) {
                Message.error(this.$t('lead.error-email'));
                return false;
            }
            this.isLoading = true;
            sendRegisterRequest({
                email: this.email.trim(),
                phone: this.phone
            })
                .then((res) => {
                    this.isLoading = false;
                    if (!has(res, 'message')) {
                        Message.error(this.$t('lead.error-unknown'));
                        return false;
                    }
                    if (res.message.status == false && res.message.field === 'email') {
                        Message.error(this.$t('lead.error-email'));
                        return false
                    } else if (res.message.status == false && res.message.field === 'phone') {
                        Message.error(this.$t('lead.error-phone'));
                        return false
                    } else if (res.message.status == false && res.message.has_account == true) {
                        Message.error(this.$t('lead.request-has-account'));
                        return false;
                    } else if (res.message.status == false && res.message.exists == true) {
                        this.requestExists = true;
                        this.requestId = res.message.request_id;

                        Message.error(this.$t('lead.request-already-exists'));
                        return false;
                    } else if (res.message.status == false) {
                        Message.error(this.$t('lead.error-unknown'));
                        return false;
                    }

                    if (res.message.status == true) {
                        this.requestId = res.message.data.request_id;
                        this.requestSent = true;
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                });
        },
        resendRegisterRequestEmail() {
            this.isLoading = true;
            resendRegisterRequestEmail({
                email: this.email.trim(),
                phone: this.phone,
                request_id: this.requestId
            })
                .then((res) => {
                    this.isLoading = false;
                    if (!has(res, 'message')) {
                        Message.error(this.$t('lead.error-unknown'));
                        return false;
                    }
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('lead.mail-sent-success')
                    });
                })
                .catch((err) => {
                    this.isLoading = false;
                });
        }
    },
    computed: {
        message() {
            return this.$t('lead.error-404') + '...';
        },
        headerBackground() {
            return {
                'background-image': 'url(' + headerImg + ')'
            };
        },
        currentYear() {
            return new Date().getFullYear();
        }
    },
    mounted() {}
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.body {
    margin: 0;
    padding: 0;
    background: #f9f9f9;
    font-family: 'Ubuntu', sans-serif;
    font-size: 15px;
    color: #1d1d1d;
}

header {
    background-color: #f3f3f3;
    background-position: top right;
    background-repeat: no-repeat;
    height: 451px;
}

img {
    max-width: 100%;
}

.clearfix {
    clear: both;
}

.form-group .vue-tel-input {
    height: 43px !important;
    border-radius: 0 !important;
    margin: 0 auto;
}

.form-group .vue-tel-input:focus-within {
    box-shadow: none !important;
}

.form-group .vue-tel-input,
.form-group input[type='text'],
.form-group input[type='email'],
.form-group button {
    width: 100%;
    max-width: 400px;
    padding: 12px;
    color: #565656;
    border: 1px solid #ececec !important;
    outline: none;
    text-align: center;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form-group button {
    cursor: pointer;
    background: #ff724c;
    border-color: #ff724c;
    color: #fff;
}

.form-group button:hover {
    background-color: #fd7a56;
}

.container {
    width: 1200px;
    margin: -300px auto 0;
}

.container .logo {
    margin-bottom: 100px;
}

/* Top area */

.container .topArea {
    display: table;
    width: 100%;
    background: #fff;
}

.container .topArea .formWrapper {
    display: table-cell;
    width: 30%;
    vertical-align: middle;
    padding: 40px;
    text-align: center;
    position: relative;
}

.container .topArea .formWrapper .ptitle {
    font-size: 30px;
    color: #ff724c;
    text-transform: uppercase;
}

.container .topArea .formWrapper p {
    display: block;
    margin: 20px 10px;
    color: #6c6c6c;
}

.container .topArea .formWrapper span {
    display: block;
    color: #6c6c6c;
}

.container .topArea .formWrapper .form-group {
    margin: 10px 0;
}

.container .topArea .mpInfo {
    display: table-cell;
    vertical-align: middle;
    background-color: #ff724c;
    padding: 30px;
    color: #fff;
}

.container .topArea .mpInfo .colWrapper {
    width: 50%;
    float: left;
}

.container .topArea .mpInfo .colWrapper .col {
    display: table;
    width: 65%;
    margin: 20px auto;
}

.container .topArea .mpInfo .colWrapper .col .icon {
    display: table-cell;
    vertical-align: top;
    padding-right: 20px;
    font-size: 50px;
}

.container .topArea .mpInfo .colWrapper .col .text {
    font-size: 18px;
    display: table-cell;
}

/* Middle area */

.container .middleArea {
    margin: 70px 0;
}

.container .middleArea .infoText {
    text-align: center;
    padding: 0 10px;
}

.container .middleArea .infoText .ptitle {
    font-weight: bold;
    font-size: 35px;
}

.container .middleArea .infoText p {
    color: #8c8c8c;
    display: block;
    margin: 15px;
}

.container .middleArea .separator {
    width: 80px;
    height: 6px;
    background: #ff724c;
    margin: 0 auto;
}

.container .middleArea .stepsWrapper {
    margin: 50px 0;
}

.container .middleArea .stepsWrapper .step {
    display: table;
    width: 23.5%;
    float: left;
    margin-right: 2%;
}

.container .middleArea .stepsWrapper .step:nth-child(4) {
    margin-right: 0;
}

.container .middleArea .stepsWrapper .step .numberCol {
    display: table-cell;
    vertical-align: top;
    padding-right: 20px;
}

.container .middleArea .stepsWrapper .step .numberCol .number {
    background: #fff;
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
    color: #fe724e;
    border-radius: 50%;
    padding: 15px 21px;
}

.container .middleArea .stepsWrapper .step .infoCol .ptitle {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
}

.container .middleArea .stepsWrapper .step .infoCol ul {
    padding-left: 20px;
    font-size: 14px;
}

.container .middleArea .stepsWrapper .step .infoCol ul li {
    color: #404040;
    margin-bottom: 15px;
}

.copyright {
    font-size: 12px;
    color: #8c8c8c;
    text-align: center;
    padding: 30px 15px;
    background-color: #fff;
}

.copyright .company {
    color: #333;
    font-size: 13px;
}

/* Responsive */

@media screen and (max-width: 1200px) {
    .container {
        width: 100%;
    }

    .container .logo {
        background: #f3f3f3;
        display: inline-block;
        padding: 20px;
        border-radius: 10px;
        float: right;
        margin-right: 20px;
        max-width: 200px;
    }
}

@media screen and (max-width: 1000px) {
    .container .topArea {
        display: table;
    }

    .container .topArea .formWrapper {
        display: block;
        width: auto;
    }

    .container .topArea .mpInfo {
        display: block;
    }

    .container .middleArea {
        margin: 40px 0 0;
    }

    .container .middleArea .stepsWrapper {
        margin-bottom: 0;
    }

    .container .middleArea .stepsWrapper .step {
        width: 48%;
        margin-right: 4%;
    }

    .container .middleArea .stepsWrapper .step:nth-child(2),
    .container .middleArea .stepsWrapper .step:nth-child(4) {
        margin-right: 0;
    }

    .container .middleArea .stepsWrapper .step .numberCol {
        width: 30%;
        text-align: right;
    }

    .container .middleArea .stepsWrapper .step .numberCol .number {
        display: inline-block;
    }
}

@media screen and (max-width: 700px) {
    header {
        display: none;
    }

    .container {
        margin: 0;
    }

    .container .logo {
        text-align: center;
        margin: 20px;
        background: none;
        float: none;
        padding: 0;
        border-radius: 0;
        display: block;
        max-width: none;
    }

    .container .logo img {
        max-width: 200px;
        height: 100%;
    }

    .container .topArea .mpInfo .colWrapper {
        float: none;
        width: auto;
    }

    .container .topArea .mpInfo .colWrapper .col {
        width: 100%;
        margin: 30px auto;
    }

    .container .topArea .mpInfo .colWrapper .col .text {
        vertical-align: middle;
    }

    .container .middleArea .stepsWrapper .step {
        width: 100%;
        margin: 15px 0;
    }
}

.lang-select {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 34px;
    cursor: pointer;
}

.stats {
    text-align: center;
    padding: 10px 0;
    color: #404040;
}

.cel {
    font-weight: bold;
    color: #fe724e;
}

.btn {
    font-size: 100%;
}

p[align='left'] {
    padding: 0 20px;
}
</style>
