import fetch from '@/utils/fetch';

export function sendEmailRequest(data) {
  return fetch({
    url: '/lead/sendEmailRequest',
    method: 'post',
    data
  });
}

export function sendRegisterRequest(data) {
  return fetch({
    url: '/lead/sendRegisterRequest',
    method: 'post',
    data
  });
}

export function resendRegisterRequestEmail(data) {
  return fetch({
    url: '/lead/resendRegisterRequestEmail',
    method: 'post',
    data
  });
}

export function getRegisterRequest(data) {
  return fetch({
    url: '/lead/getRegisterRequest',
    method: 'post',
    data
  });
}

export function getRegisterCities(data) {
  return fetch({
    url: '/lead/getRegisterCities',
    method: 'post',
    data
  });
}

export function addAccount(data) {
  return fetch({
    url: '/lead/addAccount',
    method: 'post',
    data
  });
}

export function checkName(data) {
  return fetch({
    url: '/lead/checkName',
    method: 'post',
    data
  });
}

export function checkUsername(data) {
  return fetch({
    url: '/lead/checkUsername',
    method: 'post',
    data
  });
}

export function checkIBAN(data) {
  return fetch({
    url: '/lead/checkIBAN',
    method: 'post',
    data
  });
}