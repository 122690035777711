<template>
	<el-dropdown class="lang-dropdown" @command="handleLanguageChange" trigger="click">
		<span class="el-dropdown-link">
			<span class="icon current-lang">
				<el-tooltip effect="dark" :content="$t('components.lang')" placement="bottom-end">
					<svg-icon :icon-class="currentLang" />
					<span class="lang-text">{{currentLang}}</span>
				</el-tooltip>
			</span>
		</span>
		<el-dropdown-menu slot="dropdown">
			<el-dropdown-item command="ro" :disabled="isCurrentLang('ro')">
				<svg-icon icon-class="ro" />&nbsp;RO</el-dropdown-item>
			<el-dropdown-item command="en" :disabled="isCurrentLang('en')">
				<svg-icon icon-class="en" />&nbsp;EN</el-dropdown-item>
		</el-dropdown-menu>
	</el-dropdown>
</template>

<script>
	export default {
		computed: {
			currentLang() {
				return this.$store.getters['app/currentLang'];
			}
		},
		methods: {
			isCurrentLang(lang) {
				return this.currentLang === lang;
			},
			handleLanguageChange(lang) {
				if (typeof lang === 'undefined') return false;
				this.$i18n.locale = lang;
				this.$store.dispatch('app/ChangeLanguage', lang).then((_) => {
					location.reload();
				});
			}
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	.el-dropdown {
		color: #9F9F9F;
		fill: #9F9F9F;
		display: block;
		font-size: 27px;

		.lang-text {
			display: block;
			font-size: 12px;
			text-transform: uppercase;
			border: 2px solid #dedede;
			padding: 3px 6px;
			border-radius: 4px;
			font-weight: bold;
		}
	}
</style>